import React, { Component } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";
import "videojs-playlist";
import "videojs-playlist-ui";
import "videojs-playlist-ui/dist/videojs-playlist-ui.css";
import "./Player.css";
import $ from 'jquery';

const defaultOptions: VideoJsPlayerOptions = {
    controls: true
};

export interface BasePlayerProps {
    options?: VideoJsPlayerOptions;
    onReady?: (player: VideoJsPlayer) => void;
    style?: React.CSSProperties;
    className?: string;
    videoClassName?: string;
    playList?: any
    updatedPlaylist?: any
    isFilterClicked?: any
}

export default class BasePlayer extends Component<BasePlayerProps> {
    videoNode?: HTMLVideoElement;
    player?: VideoJsPlayer;


    componentDidMount() {
        const { options, onReady = () => { } } = this.props;
        const videoJsOptions: VideoJsPlayerOptions = {
            ...defaultOptions,
            ...options
        };

        // instantiate Video.js
        this.player = videojs(
            this.videoNode,
            videoJsOptions,
            function onPlayerReady(this: VideoJsPlayer) {

                var myPlayer = this;

                /* add PREV and NEXT buttons */
                var button = videojs.getComponent("Button");
                var PrevButton = videojs.extend(button, {
                    //constructor: function(player, options) {
                    constructor: function () {
                        button.apply(this, arguments);
                        // this.addClass("icon-angle-left");
                        this.addClass("vjs-icon-previous-item");
                        this.controlText("Previous");
                    },
                    handleClick: function () {
                        console.log("clickedPrevious");
                        myPlayer.playlist.previous();
                    },
                });

                videojs.registerComponent("PrevButton", PrevButton);
                myPlayer
                    .getChild("controlBar")
                    .addChild("PrevButton", {}, 0);

                var NextButton = videojs.extend(button, {
                    constructor: function () {
                        button.apply(this, arguments);
                        // this.addClass("icon-angle-right");
                        this.addClass("vjs-icon-next-item");
                        this.controlText("Next");
                    },
                    handleClick: function () {
                        console.log("clickednext");
                        myPlayer.playlist.next();
                    },
                });

                videojs.registerComponent("NextButton", NextButton);
                myPlayer
                    .getChild("controlBar")
                    .addChild("NextButton", {}, 2);

                onReady(this);
                this.on("error", () => {
                    myPlayer.playlist.currentItem(myPlayer.playlist.currentItem()-1); 
                    myPlayer.playlist.currentItem(myPlayer.playlist.currentItem()+1); 
                    console.error(
                        "VIDEOJS: ERROR: currentSources:",
                        this.currentSources()
                    );
                });
                this.on("play", () => {
                    console.log("VIDEOJS: currentSources:", this.currentSources());
                    
                    const node = document.querySelector(".vjs-playlist") as HTMLElement | null;
                    node.scrollTop = this.playlist.currentItem()/this.playlistMenu.items.length*node.scrollHeight;
                });
            }
        );
    }

    // destroy player on unmount
    // componentWillUnmount() {
    //     if (this.player) {
    //         this.player.dispose();
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('did mount');
        const { isFilterClicked, updatedPlaylist , playList= () => { } } = this.props;
        // console.log('updatedPlaylist' , updatedPlaylist.length);
        // console.log('prevProps.playList' , prevProps.playList.length);
        // console.log('prevProps.updatedPlaylist' , prevProps.updatedPlaylist.length);
        // console.log('playList' , playList.length);
        // console.log('isFilterClicked' , isFilterClicked);
        if ((updatedPlaylist.length !== 0 && updatedPlaylist.length !== prevProps.updatedPlaylist.length)|| (isFilterClicked)) {
            this.player.playlist(updatedPlaylist);
        }
        
        
        // console.log(playList);
    }

    render() {
        const { style } = this.props;
        return (
            <div id="videoAndPlaylistWrapper" style={style}>
                {/* data-vjs-player ref: https://docs.videojs.com/tutorial-react.html */}
                <div data-vjs-player="true">
                    <video
                        ref={node => {
                            if (node) {
                                this.videoNode = node;
                            }
                        }}
                        className="video-js"
                    />
                    <div className="vjs-playlist video-js vamsi"></div>
                </div>
            </div>
        );
    }
}
