import { makeStyles } from "@material-ui/core/styles";

export const useIntegrityVerificationsStyles = makeStyles((theme) => ({
    integrityWrapper: {
        width: "99%",
        height: "99%",
        // backgroundColor: 'lightgrey',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    pageTitle: {
        display: "flex",
        justifyContent: "center",
        // height: '3%',
    },
    products: {
        display: "grid",
        gridTemplateColumns: "47% 26% 26%",
        justifyItems: 'center',
    },
    gaps: {
        color: 'red'
    },
    mainPage: {
        display: "flex",
        justifyContent: "space-between",
    },
    // table:{
    //     width: '250px',
    //     height: '730px',
    //     backgroundColor: 'grey',
    // }
}));
