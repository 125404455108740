import { makeStyles } from "@material-ui/core/styles";

export const useLoadingScreenStyles = makeStyles((theme) => ({
    loading_screen: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
}));
