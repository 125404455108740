import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/actions';
import LoadingScreen from './LoadingScreen';
import { useAuth0 } from '@auth0/auth0-react';
import { selectors } from '../../store/selectors';
import cloneDeep from 'lodash.clonedeep';
import { useIntegrityVerificationsStyles } from './IntegrityVerificationsStyles';
import { authenticateAWS, getFiles } from 'common/utils';
import { VideoBase } from './VideoBase';
import Location from './Location';
import ErrorPage from './ErrorPage';
import GapTable from './GapTable';
import { allowedSport } from 'common/constants';
import logo from '../../png/logo1.png';
import { useVideoBaseStyles } from './VideoBaseStyles';


const IntegrityVerifications = ({ sport, event_id }) => {

    const dispatch = useDispatch();
    const classes = useIntegrityVerificationsStyles();
    const classesVideo = useVideoBaseStyles();
    const streamFiles = cloneDeep(useSelector(selectors['common'].selectStreamFiles));
    const locationFiles = cloneDeep(useSelector(selectors['common'].selectLocationFiles));

    //Populate number of Grids(matches) will have opened in Feed Monitor
    const config = useSelector(selectors['common'].selectConfig);
    const organizationId = String(useSelector(selectors['common'].selectOrganizationId));
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

    useEffect(() => {
        const getToken = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: 'https://' + config['AUTH0_DOMAIN'] + '/api/v2/',
                    // scope: 'feed-monitor:admin', /* no such role added so far! */
                });

                dispatch(actions['common'].setAuth0Token(token));
            } catch (e) {
                const err = cloneDeep(e);
                if (err.error === 'login_required' || err.error === 'consent_required') {
                    await loginWithRedirect({ organization: organizationId });
                }

                console.log(err);
            }
        }
        getToken();

        /* authenticate once */
        const s3 = authenticateAWS();
        getFiles(sport, event_id, 'stream', s3);
        getFiles(sport, event_id, 'location', s3);

        /* Dynamic load for all files on every 10 seconds */
        const timer = setInterval(() => {
            console.log('get all S3 bucket files');
            getFiles(sport, event_id, 'stream', s3);
            getFiles(sport, event_id, 'location', s3);
        }, 10 * 1000);

        return () => {
            clearInterval(timer);
        };

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, []);

    // console.log(streamFiles);
    // console.log(locationFiles);

    if ((streamFiles && streamFiles['fullPath'] && streamFiles['fullPath'].length) ||
        (locationFiles && locationFiles['fullPath'] && locationFiles['fullPath'].length)) {
        return (
            <div className={classes.integrityWrapper}>

                <img id="logo" src={logo} alt="Logo" />

                <div className={classes.pageTitle}>
                    <h1>INTEGRITY VERIFICATIONS</h1>
                </div>

                <div className={classes.products}>
                    <h2>Stream</h2>
                    <h2 className={classes.gaps}>Gaps: {streamFiles && streamFiles['gap'] && streamFiles['gap'].length}</h2>
                    <h2>GPS</h2>
                </div>

                <div className={classes.mainPage}>
                    {streamFiles['fullPath'] && streamFiles['fullPath'].length ? <VideoBase event_id={event_id} sport={sport} /> : <div className={classesVideo.videoPlayerField}>No stream files</div>}
                    {streamFiles['gap'] && <GapTable />}
                    {locationFiles['fullPath'] && <Location />}
                </div>
            </div>
        );
    } else if (allowedSport.includes(sport) && event_id && streamFiles['fullPath'] && streamFiles['fullPath'].length === 0) {
        return (
            <ErrorPage value={"No files in bucket for this Event"} />
        )
    }
    else if (event_id && allowedSport.includes(sport)) {
        return (
            <LoadingScreen />
        );
    }
    else {
        return (
            <ErrorPage value={"Missing/Wrong url parameters"} />
        );
    }
}

export default IntegrityVerifications;