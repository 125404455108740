import { makeStyles } from "@material-ui/core/styles";

export const useLocationStyles = makeStyles((theme) => ({
    locationWrapper:{
        display: 'flex',
        // justifyContent: 'center',
        aligntItems: 'center',
        gap: 80,
        width: '28%',
        height: '99%',
        flexDirection: 'column'
    },
    iframe:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    }
}));
