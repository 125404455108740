import React, { useState, useEffect } from "react";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "videojs-playlist";
import "videojs-playlist-ui";
import "videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css";
import "videojs-thumbnail-sprite";
import "videojs-overlay";
import "video.js/dist/video-js.css";
import cloneDeep from "lodash.clonedeep";
import { useSelector } from "react-redux";
import { selectors } from "../../store/selectors";
import { useVideoBaseStyles } from "./VideoBaseStyles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from '@mui/material/Button';
import { VideoJsPlayer } from "video.js";
import Player from "./Player";
import { isValidatedClick } from "common/utils";
import { locationFilterItems } from 'common/utils';

export const VideoBase = ({ event_id, sport }) => {
    const classes = useVideoBaseStyles();
    const config = useSelector(selectors["common"].selectConfig);
    const streamFiles = cloneDeep(
        useSelector(selectors["common"].selectStreamFiles),
    );

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [count, setCount] = useState(0);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [updatedPlaylist, setUpdatedPlaylist] = useState([]);

    let playList = [];
    const setPlaylist = () => {
        
        let tempCount = 0;
        let ofset = new Date(startTime || endTime).getTimezoneOffset() * (60 * 1000);   /* thats the difference between GMT and local time */

        let selectedStartTime = new Date(startTime).getTime() - ofset;
        let selectedEndTime = new Date(endTime).getTime() - ofset;

        if (streamFiles && streamFiles.fullPath && streamFiles.fullPath.length) {
            streamFiles.fullPath.forEach((element: any, index: number) => {
                let timeDiff = 0;
                
                /*  minus 10 seconds as this timestamp is when the file is saved, 
                    so basicaly we need the start stream timestamp 
                    1684476000000 = 2023-05-19 06:00:00 UTC
                    we need to remove 10 seconds for all matches before the fix for starting time of recording has been applied on PROD
                    PBPC2-1784
                */
                if ( 1684476000000 > Number(streamFiles.fileNames[index])) {    
                    timeDiff = 10 * 1000;
                }

                const dateFormat = new Date(Number(streamFiles.fileNames[index]) /* + ofset */ - timeDiff); 
                const name = dateFormat.toUTCString().slice(4, -4) + ' UTC';
                
                let subName = '';
                if (streamFiles.timeOfUploading[index]) {
                    subName = streamFiles.timeOfUploading[index].toUTCString().slice(4, -4) + ' UTC';
                } 

                /* check which files passes the filters and push to playList */
                if ((startTime === null && endTime === null) ||
                    (dateFormat.getTime() >= selectedStartTime &&
                        dateFormat.getTime() <= selectedEndTime)) {
                    tempCount++;
                    playList.push({
                        name: name + '\n uploaded at ' + subName,
                        sources: [
                            {
                                src: config["S3_CLOUDFRONT_URL"] + element,
                                type: "video/mp4",
                            },
                        ],
                    });
                }
            });
        }

        // console.log('playList hook', playList.length);
        setUpdatedPlaylist(playList);
        /* set file count */
        setCount(tempCount);

        // console.log(playList);
        // return playList;
    }

    const handleChange = (type: string, newValue: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (type === "start") {
            setStartTime(newValue);
        } else if (type === "end") {
            setEndTime(newValue);
        } else if (type === "reset") {
            /* reset filters */
            setStartTime(null);
            setEndTime(null);
        }
    };

    useEffect(() => {
        if (startTime === null && endTime === null) {
            // console.log('useEffect trigger');
            setPlaylist();
        }
    }, [startTime, endTime /* , streamFiles */]);

    return (
        <div className={classes.videoPlayerField}>
            <div className={classes.filters}>

                <p className={classes.bold}>
                    Event: {event_id}
                </p>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={classes.formControl}>
                        <DateTimePicker
                            inputFormat="DD/MM/YY HH:mm:ss"
                            ampm={false}
                            label="Start time (UTC)"
                            value={startTime}
                            onChange={(newValue) => {
                                setIsFilterClicked(false);
                                handleChange("start", newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>

                    <div className={classes.formControl}>
                        <DateTimePicker
                            inputFormat="DD/MM/YY HH:mm:ss"
                            ampm={false}
                            label="End time (UTC)"
                            value={endTime}
                            onChange={(newValue) => {
                                setIsFilterClicked(false);
                                handleChange("end", newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </LocalizationProvider>
                <Button
                    id="filterVideo"
                    color="success"
                    // variant="outlined"
                    variant="contained"
                    size="large"
                    onClick={() => {
                        if (isValidatedClick(startTime, endTime)) {
                            setIsFilterClicked(true); /* update componentDidUpdate when === true */
                            setIsFilterClicked(false); /* hacky - we want this to be false , as when s3 bucket is updated to now go in componentDidUpdate */
                            setPlaylist();
                            locationFilterItems();
                        }
                    }}
                >
                    FILTER&UPDATE
                </Button>
                <Button
                    disabled={startTime || endTime ? false : true}
                    id="resetButton"
                    color="error"
                    size="small"
                    variant="outlined"
                    onClick={(newValue) => {
                        handleChange("reset", newValue);
                    }}
                >
                    Reset
                </Button>

                <p className={classes.bold}> Files: {count} </p>
            </div>

            <div className={classes.videoPlayerBaseAndPlaylist}>
                <Player
                    playList={playList}
                    updatedPlaylist={updatedPlaylist} /* updated playlist used in componentDidUpdate */
                    isFilterClicked={isFilterClicked}
                    options={{
                        autoplay: false,
                        playbackRates: [0.5, 1, 1.5, 2]
                    }}

                    onReady={(player: VideoJsPlayer) => {
                        if (player.playlist) {
                            player.playlist(playList); /* default playlist */
                            player.playlist.autoadvance(0);
                            player.playlist.last();
                            player.playlistUi();

                            const node = document.querySelector(".vjs-playlist") as HTMLElement | null;
                            node.scrollTop = node.scrollHeight;
                        }
                    }}
                />
            </div>
        </div>
    );
};
