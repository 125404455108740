import * as React from 'react';

const ErrorPage = ({value}) => {
    return (
        <div className="errorField" data-cy="error-page" data-testid="error-page">
            <p>{value}</p>
        </div>
    )
};


export default ErrorPage;