import * as React from 'react';
import ReactLoading from "react-loading";
import { useLoadingScreenStyles } from './LoadingScreenStyles';

const LoadingScreen = () => {
    const classes = useLoadingScreenStyles();

    return (
        <div
            data-cy="loading-screen"
            data-testid="loading-screen"
            className={classes.loading_screen}
        >
            <h1>Loading...</h1>
            <ReactLoading type={"spin"} color={"orange"} width={'30%'} />
        </div>
    )
};


export default LoadingScreen;