import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../common/utils';
import { actions } from '../../store/actions';
import { selectors } from '../../store/selectors';
import App from '../../App';
import AuthProvider from 'components/common/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';


const ConfigProvider = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectors['common'].selectConfig);


    useEffect(() => {
        const getConfigData = async () => {
            try {
                const config = await fetchData('./config.json');
                dispatch(actions['common'].setConfig(config));
            } catch (e) {
                console.log(e);
            }
        };

        getConfigData();
    }, [dispatch]);

    return (
        Object.keys(config).length > 0 &&

        <Auth0Provider 
            domain={config['AUTH0_DOMAIN']} 
            clientId={config['AUTH0_CLIENT_ID']}
            redirectUri={window.location.href}
            audience={'https://' + config['AUTH0_DOMAIN'] + '/api/v2/'}
        >
            <AuthProvider>
                <Router>
                    <App />
                </Router>
            </AuthProvider>
        </Auth0Provider>
    )
    // return Object.keys(config).length > 0 && children;
};

export default ConfigProvider;
