import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGapTableStyles } from './GabTableStyles';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { selectors } from 'store/selectors';


const GapTable = () => {
    const classes = useGapTableStyles();
    const gapFiles = cloneDeep(useSelector(selectors['common'].selectStreamFiles)['gap']);
    const rows = [];

    gapFiles.forEach((element) => {
        let timeDiff = 0;

        /*  minus 10 seconds as this timestamp is when the file is saved, 
            so basicaly we need the start stream timestamp 
        
            1684476000000 = 2023-05-19 06:00:00 UTC
            we need to remove 10 seconds for all matches before the fix for starting time of recording has been applied on PROD
            PBPC2-1784
        */
        if ( 1684476000000 > Number(element[0])) {    
            timeDiff = 10 * 1000;
        }

        const dateFormatCurrent = new Date(Number(element[0]) - timeDiff).toUTCString().slice(4, -4) + ' UTC';
        const dateFormatNext = new Date(Number(element[1]) - timeDiff).toUTCString().slice(4, -4) + ' UTC';
        const minutes = Math.floor(element[2] / 60);
        const seconds = element[2] - minutes * 60;

        rows.push({
            startTime: dateFormatCurrent,
            endTime: dateFormatNext,
            duration: '' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)
        })
    });

    return (
        <div className={classes.gapTableField}>

            <div className={classes.table}>
                <TableContainer component={Paper} /* sx={{border: '2px dashed red' }} */>
                    <Table sx={{ minWidth: 350 }} size="small" aria-label="Gap Table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: 'bold', padding: 0 }}>Start Time</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', padding: 0 }}>End Time</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', padding: 0 }}>Duration<br />(mm:ss)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.startTime}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" align="center" sx={{ fontSize: '0.7em', padding: '13px 0px' }}>
                                        {row.startTime}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7em', padding: '13px 0px' }}>{row.endTime}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7em', padding: '13px 0px' }}>{row.duration}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default GapTable;