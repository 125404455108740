import { CommonState } from "./types";
import cloneDeep from "lodash.clonedeep";

const selectorsObj = {};

selectorsObj["common"] = {
    selectUser: (state: CommonState) => state["common"].user,
    selectAuthorized: (state: CommonState) => state["common"].authorized,
    selectConfig: (state: CommonState) => state["common"].config,
    selectAuth0Token: (state: CommonState) => state["common"].auth0Token,
    selectOrganizationId: (state: CommonState) =>
        state["common"].organizationId,
    selectStreamFiles: (state: CommonState) => state["common"].streamFiles,
    selectLocationFiles: (state: CommonState) => state["common"].locationFiles,
    selectListItems: (state: CommonState) => state["common"].listItems,
    selectCenter: (state: CommonState) => state["common"].center,
    selectLocationDropDownDisabled: (state: CommonState) => state["common"].locationDropDownDisabled
};

export const selectors = cloneDeep(selectorsObj);
