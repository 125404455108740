// @ts-nocheck
import AWS from "aws-sdk";
import { MenuItem } from '@material-ui/core';
import { store } from "../store";
import { actions } from "../store/actions";


export const authenticateAWS = () => {
    const config = store.getState()["common"].config;
    const s3 = new AWS.S3({
        accessKeyId: config["S3_ACCESS_KEY_ID"],
        secretAccessKey: config["S3_SECRET_ACCESS_KEY"],
        region: config["S3_REGION"],
    });
    return s3;
};

export const getFiles = async (
    sport: string,
    event_id: string,
    path: string,
    s3: any,
) => {
    const config = store.getState()["common"].config;
    const params = {
        Bucket: config["S3_BUCKET"],
        Delimiter: "/",
        Prefix: sport + "/" + event_id + "/" + path + "/",
    };

    const result = {
        fullPath: [],
        fileNames: [],
        gap: [],
        timeOfUploading: []
    };

    try {
        const data = await s3.listObjects(params).promise();
        let prevElement = '';
        let currentElement = '';

        /* map instad of forEeach */
        data["Contents"].map((element, index) => {
            let splitArray = element["Key"].split("/");
            if (splitArray[splitArray.length - 1].split(".")[0]) { /* check if we have at least one file */
                const fileName = splitArray[splitArray.length - 1].split(".")[0];

                if (path === "stream") { /*  keep this only for Stream files */
                    if (prevElement === '') {
                        prevElement = fileName;
                    }
                    currentElement = fileName;
                    const duration = Math.floor((currentElement - prevElement) / 1000);

                    if (((currentElement - prevElement) / 1000 > 22)) { /* sanity check is for 22 just in case for some delay (should be ~20 seconds) */
                        result.gap.push([prevElement, currentElement, duration]) /* store gaps timestamps */
                    }
                    prevElement = currentElement;

                    if (element["LastModified"]) {
                        result.timeOfUploading.push(element["LastModified"]);
                    }
                }

                result.fileNames.push(
                    fileName,
                );
                result.fullPath.push(element["Key"]);
            }
        });

        if (path === "stream") {
            store.dispatch(actions["common"].setStreamFiles(result));
        } else if (path === "location") {
            store.dispatch(actions["common"].setLocationFiles(result));
        }
    } catch (err) {
        console.log(err);
    }
};

/* validation for filters before click */
export const isValidatedClick = (startTime, endTime) => {
    let result = false;
    if (
        /* both filters should be filled with time AND start time should be smaller than end time */
        (startTime !== null && endTime !== null && startTime < endTime) ||
        (startTime === null && endTime === null)
        /*  when filters are empty we show all files for that match */
    ) {
        result = true;
        /* trigger useEffect to enable correct files */
    } else if (startTime === null || endTime === null) {
        alert("Fill both filters");
        result = false;
    } else {
        alert("Invalid filter");
        result = false;
    }
    return result;
};

export const fetchData = async (url: string) => {
    try {
        const data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        return await data.json();
    } catch (e) {
        return null;
    }
};

export const locationFilterItems = () => {
    const config = store.getState()["common"].config;
    const locationFiles = store.getState()['common'].locationFiles;
    const locationDropdownItems = [];
    let counter = 0;

    if (locationFiles && locationFiles.fullPath) {
        store.dispatch(actions["common"].setLocationDropDownDisabled(true));
        locationFiles.fullPath.forEach(async (element, index) => {
            try {
                const data = await fetchData(config["S3_CLOUDFRONT_URL"] + element);
                if (data) {
                    locationDropdownItems[index] = (<MenuItem key={"location_item_" + index} value={data.position + '_' + index}>{data.phase} at {new Date(data.timestamp).toUTCString().slice(4, -4)} UTC</MenuItem>)
                    if (index === 0) {
                        store.dispatch(actions["common"].setCenter(data.position + '_' + index));
                    }
                    counter++;
                    if (counter === locationFiles.fullPath.length) {
                        store.dispatch(actions["common"].setListItems(locationDropdownItems));
                        store.dispatch(actions["common"].setLocationDropDownDisabled(false));
                    }
                }
            }
            catch (err) {
                store.dispatch(actions["common"].setLocationDropDownDisabled(false));
                console.log(err);
            }
        });
    }
}
