import { makeStyles } from "@material-ui/core/styles";

export const useVideoBaseStyles = makeStyles((theme) => ({
    videoPlayerField:{
        display: 'flex',
        height: '99%',
        width: '49%',
        flexDirection: "column",
    },
    videoPlayerBaseAndPlaylist:{
        display: 'flex',
        // padding: '5 5',
        // padding: 30,
        width: '99%',
        margin: "10px",
        // position: "relative",
        // borderRight: '1px solid'
    },
    // videoBase:{
    //     position: "relative",
    //     margin: "10px",
    //     // height: "80%",
    //     width: "99%",
    // },
    filters:{
        display: 'flex',
        justifyContent: 'space-around',
        height: '20%',
        paddingLeft: '1%',
        width: '99%',
        alignItems: 'center'
    },
    formControl: {
        width: '20%'
    },
    bold:{
        fontWeight: 'bold'
    }
}));
