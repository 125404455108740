import { AnyAction, combineReducers } from "redux";
import { CommonState } from "./types";
import { actionTypes } from "./actions";

export const initialCommonState: CommonState = {
    user: "",
    authorized: false,
    config: {},
    auth0Token: "",
    organizationId: "",
    streamFiles: {},
    locationFiles: {},
    listItems: [],
    center: "",
    locationDropDownDisabled: false
};

export const commonReducer = (
    state = initialCommonState,
    action: AnyAction,
): CommonState => {
    switch (action.type) {
        case actionTypes["common"].setUser: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case actionTypes["common"].setAuthorized: {
            return {
                ...state,
                authorized: true,
            };
        }
        case actionTypes["common"].setConfig: {
            return {
                ...state,
                config: action.payload,
            };
        }
        case actionTypes["common"].setAuth0Token: {
            return {
                ...state,
                auth0Token: action.payload,
            };
        }
        case actionTypes["common"].setOrganizationId: {
            return {
                ...state,
                organizationId: action.payload,
            };
        }
        case actionTypes["common"].setStreamFiles: {
            return {
                ...state,
                streamFiles: action.payload,
            };
        }
        case actionTypes["common"].setLocationFiles: {
            return {
                ...state,
                locationFiles: action.payload,
            };
        }
        case actionTypes["common"].setListItems: {
            return {
                ...state,
                listItems: action.payload,
            };
        }
        case actionTypes["common"].setCenter: {
            return {
                ...state,
                center: action.payload,
            };
        }
        case actionTypes["common"].setLocationDropDownDisabled: {
            return {
                ...state,
                locationDropDownDisabled: action.payload,
            };
        }
        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    common: commonReducer,
});
