import React from "react";
import IntegrityVerifications from './components/common/IntegrityVerifications';
// eslint-disable-next-line
import { Route, Routes  } from 'react-router-dom';
import "./App.css";


export const url = window.location.search;
const params = new URLSearchParams(url);
export let event_id = params.get('event_id');
export let sport = params.get('sport');

const App = () => {

    return (
        <div className="App" id="App" data-testid="App">
            <Routes>
                <Route path="/" element={<IntegrityVerifications sport={sport} event_id={event_id} />} />
            </Routes>
        </div>
    );
}


export default App;
