import React, { useEffect } from 'react';
import { FormControl } from '@mui/material';
import { Select, InputLabel } from '@material-ui/core';
import cloneDeep from "lodash.clonedeep";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../store/actions';
import { useLocationStyles } from './LocationStyles';
import { locationFilterItems } from 'common/utils';
import { selectors } from "../../store/selectors";



const Location = () => {
    const classes = useLocationStyles();
    const dispatch = useDispatch();
    const center = cloneDeep(useSelector(selectors["common"].selectCenter));
    const listItems = cloneDeep(useSelector(selectors["common"].selectListItems));
    const locationDropDownDisabled = cloneDeep(useSelector(selectors["common"].selectLocationDropDownDisabled));
        
    
    useEffect(() => {
        locationFilterItems();
        // eslint-disable-next-line
    }, []);


    const handleChange = (e) => {
        dispatch(actions['common'].setCenter(e.target.value));
    }

    return (
        <div className={classes.locationWrapper}>
            <FormControl >
                <InputLabel id="location-label">Location</InputLabel>
                <Select
                    labelId="location-label"
                    id="location-select"
                    value={center}
                    disabled={locationDropDownDisabled}
                    onChange={(e) => { handleChange(e) }}
                >

                    {listItems}
                </Select>
            </FormControl>

            <div className={classes.iframe}>
                {
                    listItems.length ? 
                        <iframe
                            title="map"
                            width="600"
                            height="600"
                            frameBorder="0"
                            style={{ border: 0 }}
                            scrolling="no"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={"https://maps.google.com/maps?q=" + center.split('_')[0] + "&t=&z=16&ie=UTF8&iwloc=B&output=embed"}
                            allowFullScreen>
                        </iframe>
                    :
                        <div></div>
                }
            </div>

        </div>
    )
}

export default Location;
