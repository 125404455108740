import { AnyAction } from "redux";
import cloneDeep from "lodash.clonedeep";

const actionsObj = {};
const actionsTypesObj = {};

actionsTypesObj['common'] = {
    setUser: "setUser",
    setAuthorized: "setAuthorized",
    setConfig: "setConfig",
    setAuth0Token: "setAuth0Token",
    setOrganizationId: "setOrganizationId",
    setStreamFiles: "setStreamFiles",
    setLocationFiles: "setLocationFiles",
    setListItems: "setListItems",
    setCenter: "setCenter",
    setLocationDropDownDisabled: "setLocationDropDownDisabled"
}

actionsObj['common'] = {
    setUser: (value: string): AnyAction => ({
        type: actionsTypesObj['common'].setUser,
        payload: value,
    }),
    setAuthorized: (): AnyAction => ({
        type: actionsTypesObj['common'].setAuthorized
    }),
    setConfig: (value: string) => ({
        type: actionsTypesObj['common'].setConfig,
        payload: value
    }),
    setAuth0Token: (value: string) => ({
        type: actionsTypesObj['common'].setAuth0Token,
        payload: value
    }),
    setOrganizationId: (value: string) => ({
        type: actionsTypesObj['common'].setOrganizationId,
        payload: value
    }),
    setStreamFiles: (value: string) => ({
        type: actionsTypesObj['common'].setStreamFiles,
        payload: value
    }),
    setLocationFiles: (value: string) => ({
        type: actionsTypesObj['common'].setLocationFiles,
        payload: value
    }),
    setListItems: (value: object) => ({
        type: actionsTypesObj['common'].setListItems,
        payload: value
    }),
    setCenter: (value: string) => ({
        type: actionsTypesObj['common'].setCenter,
        payload: value
    }),
    setLocationDropDownDisabled: (value: boolean) => ({
        type: actionsTypesObj['common'].setLocationDropDownDisabled,
        payload: value
    })
}

export const actions = cloneDeep(actionsObj);
export const actionTypes = cloneDeep(actionsTypesObj);
