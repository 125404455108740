import { makeStyles } from "@material-ui/core/styles";

export const useGapTableStyles = makeStyles((theme) => ({
    gapTableField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // gap: '1px'
    },
    table: {
        width: "370px",
        height: "736px",
        maxWidth: "500px",
        overflow: "auto",
        // paddingLeft: '1%',
        // paddingTop: "30px",
        border: '2px dashed red',
        borderRadius: '7px', 
    },
}));
